export default {
    common: {
        'phone': '手机号',
        'home': '首页',
        'home-big': '首页',
        'chatOnline': '在线沟通',
        'mychatOnline': '我的沟通',
        'search': '搜索',
        'logout': '退出登录',
        'city': '地级市',
        'area': '县市区',
        'personal': '个人中心',
        'personal-big': '个人中心',
        'welcome': '欢迎来到揭榜挂帅平台',
        'news': '新闻资讯',
        'news-big': '新闻资讯',
        'policy-big':'政策资讯',
        'details': '详情信息',
        'viewLatest': '查看最新',
        'defaultDisplay': '默认显示',
        'keywords': '请输入关键字',
        'noData': '哎呀，暂无数据哦！',
        'industryType': '行业类型',
        'industryField': '八大高新技术领域',
        'threeHighLand': '315体系',
        'cooperationType': '合作类型',
        'projectBudget': '项目预算',
        'listStatus': '榜单状态',
        'releaseDemand': '发布榜单需求',
        'promptlyUnveil': '立即揭榜',
        'totalFound': '共',
        'qualified': '符合条件',
        'convenientEntrance': '便捷入口',
        'govList': '政府榜单',
        'govList-big': '政府榜单',
        'entList': '企业榜单',
        'entList-big': '企业榜单',
        'achList': '成果榜单',
        'achList-big': '成果榜单',
        'talentProject': '人才工程',
        'enterEnterprise': '百博入企',
        'enterEnterprise-big': '百博入企',
        'talentExpert': '人才专家',
        'talentExpert-big': '人才专家',
        'serviceAlliance': '服务联盟',
        'serviceAlliance-big': '服务联盟',
        'new': '最新推荐',
        'more': '更多',
        'serviceProcess': '服务流程',
        'generalList': '综合榜单',
        'listType': '榜单类型',
        'projectType': '项目类型',
        'jointPublishing': '联合发榜类型',
        'demandCollection': '需求征集',
        'baseInformation': '基本信息',
        'userName': '用户名',
        'researchField': '研究领域',
        'resourceType': '资源类型',
        'resourceName': '资源名称',
        'firstField': '一级领域',
        'secondField': '二级领域',
        'majorType': '专业类别',
        'technicalField': '技术领域',
        'expertName': '专家姓名',
        'education': '学历',
        'unitName': '单位名称',
        'wantPublish': '我要发布',
        'expertRegister': '专家注册',
        'myList': '我的榜单',
        'over': '已经到底了',
        'myUnveiling': '我的揭榜',
        'myAppointment': '我的预约',
        'myCollection': '我的收藏',
        'release': '发布需求',
        'outcomeEvaluation': '成果评估',
        'overseaEngineer': '海外工程师',
        'governmentListCollect': '政府榜单',
        'recommendList': '榜单推荐',
        'myExposureRisk': '我的揭榜险',
        'myTechnologyLoan': '我的科技贷',
        'publishedExperts': '发布的专家',
        'mineExperts': '我的专家',
        'keyword': '关键字',
        'pendingReview': '待审核',
        'all': '全部',
        'pass': '通过',
        'notPass': '未通过',
        'publishing': '发布中',
        'signed': '已揭榜',
        'approved': '已立项',
        'conquered': '已攻克',
        'cancelled': '已取消',
        'enterpriseName': '企业名称',
        'companyName': '公司名称',
        'accumulativeTotal': '累计',
        'entry': '个',
        'list': '榜单',
        'fulfilled': '已兑现',
        'loginIn': '请登录',
        'classification': '分类',
        'highland': '高地',
        'perfectInformation': '完善信息',
        'perfectInformationDesc': '政府榜单发布用户绑定单位信息后具备发榜资质，科研院所、新型研发机构、团队、个人及合作机构需上传相关资质材料后具备揭榜资质。',
        'evaluationList': '榜单评估',
        'evaluationListDesc': '企业填写政府榜单，平台对榜单可行性进行评估，评估通过后政府榜单在平台展示并精准推送',
        'resourceSearch': '资源寻访',
        'resourceSearchDesc': '企业政府榜单发布后，平台新闻资讯了解榜单信息对口主动揭榜，揭榜挂帅服务中心及技术转移中心定向寻访揭榜团队、科研院所及合作机构',
        'dockingDiscussion': '对接洽谈',
        'dockingDiscussionDesc': '线上信息精准推送、线下平台主动服务，发榜与揭榜双方深入洽谈，签定攻关协议，攻克技术难题',
        'comprehensiveTrace': '全程跟踪',
        'comprehensiveTraceDesc': '平台对发榜与揭榜双方的合作过程全程跟踪服务，为其提供配套政策解读及奖榜政策申报等服务',
        'proactivelyDesignProjectLists': '主动设计项目榜单',
        'bangE': '榜额',
        'staySigned': '待揭榜',
        'signing': '正在揭榜',
        'immediatelyDeclare': '立即申报',
        'simpleCompany': '公司',
        'myriad': '万',
        'industry': '行业',
        'region': '区域',
        'listContent': '内容与期望目标',
        'jieBangSubject': '揭榜主体',
        'implementationWay': '实施方式',
        'totalBudgetInputForKeyProjects': '项目攻关预算总投入',
        'researchTimeLimit': '攻关时限',
        'declarationStartTime': '申报开始时间',
        'declarationEndTime': '申报接收时间',
        'collection': '收藏',
        'cancel': '取消',
        'mainDesignContent': '主要设计内容',
        'mainTechnicalIndicators': '主要技术指标',
        'commenList': '评论列表',
        'noteInfo': '备注信息',
        'before': '前',
        'reply': '回复',
        'comment': '评论',
        'wantCollect': '我要征集',
        'projectInformation': '项目信息',
        'pleaseFillInTheFollowingCarefully': '请认真填写以下内容',
        'requireName': '需求名称',
        'R&D': '研发',
        'experiment': '实验',
        'period': '周期',
        'demand': '需求',
        'type': '类型',
        'strategic': '战略',
        'planning': '规划',
        'market': '市场',
        'recommend': '推荐',
        'source': '来源',
        'municipalGovernmentDepart': '市政府部门',
        'academics': '高校院所',
        'institution': '机构',
        'name': '名称',
        'researchSignificance': '攻关背景和意义',
        'word': '字',
        'mainResearchDevelopmentContent': '主要研发内容',
        'achievement': '成果',
        'info': '信息',
        'expectedLandmarkAchievementAndLevel': '预期标志性成果及水平',
        'technology': '技术',
        'advanced': '先进',
        'level': '水平',
        'domestic': '国内',
        'international': '国际',
        'referenceUnitAndProductModel': '对标单位及产品型号',
        'benchmarkingProductsTechnology': '对标产品技术',
        'R&dProductsTechnology': '研发产品技术',
        'expectedProgress': '攻关水平',
        'canChooseMore': '可多选',
        'leadDepopulatedZoneTech': '领跑（无人区）技术',
        'domesticSubstitutionDemonstrationApplication': '达到对标国际先进技术水平',
        'domesticInsteadTrial': '超过对标国际先进技术水平',
        'domesticSubstitutionPrototype': '开发出国产化替代的样品并实现应用',
        'other': '开发出国产化替代的产品形成批量生产能力',
        'unitInfo': '单位信息',
        'recommendTotalRDInvest': '建议研发总投入',
        'recommendAdvantagesUnit': '推荐优势单位',
        'fillInUnit': '填报单位',
        'informant': '填报人',
        'contactPhoneNumber': '联系人手机',
        'validityPeriod': '有效期',
        'negotiable': '面议',
        'applyCheckList': '申请验榜',
        'haveDifficulty': '有困难',
        'findResources': '找资源',
        'desiredGoals': '期望达成的目标',
        'enterpriseProfile': '企业简介',
        'bigMarket': '大市场',
        'contactNumber': '联系方式',
        'enterprise': '企业',
        'platform': '平台',
        'operationStaff': '运营人员',
        'wantBillboard': '我要发榜',
        'promise': '承诺',
        'platformPromiseDesc': '您提交的资料经过系统加密，绝不外泄，请放心填写！',
        'cooperationWay': '合作方式',
        'degreeLevel': '程度级别',
        'emergency': '紧急',
        'noUrgent': '不紧急',
        'municipal': '市级',
        'counties': '县市区',
        'expectTotalProjectInvest': '预期项目总投入(万元)',
        'listAmountType': '榜单金额类型',
        'listAmount': '榜单金额(万元)',
        'listAmountSimple': '榜单金额',
        'listMinAmount': '榜单最小金额(万元)',
        'listMaxAmount': '榜单最大金额(万元)',
        'contact': '联系人',
        'listPublicDegree': '榜单公开程度',
        'partiallyExposedType': '部分公开类型',
        'industrialChainName': '十条产业链',
        'listIntroducePictures': '榜单介绍图片',
        'listIntroducePicturesDesc': '请上传企业形象图片或难题榜单相关图片',
        'supplementaryInformation': '补充信息',
        'upDetailPicturesVideos': '上传详情图片/视频',
        "companyDetailPicturesVideosFor": "企业/技术需求介绍；",
        'upDetailPicturesVideosDesc': '图片需小于5M，支持jpg、gif、bmp、png格式\n800×800以上图片',
        'clickSelectImgVedio': '点击选择图片/视频',
        'confirmSubImmediately': '确认无误，立即提交',
        'saveDraft': '保存草稿',
        'applyTrophy': '申请奖榜',
        'awardSubsidyProject': '奖励（补助）项目',
        'userType': '用户类型',
        'whetherBidding': '是否竞拍',
        'yes': '是',
        'no': '否',
        'applyUnit': '申报单位',
        'applyPerson': '申报个人',
        'socialCreditCode': '统一社会信用代码',
        'idCard': '身份证号',
        'legalRepresentative': '法定代表人',
        'unitNature': '单位性质',
        'countyCityDistrict': '所在县/市/区',
        'townStreetArea': '乡镇/街道/开发区',
        'depositBank': '开户行',
        'bankAccount': '银行账号',
        'countySubsidyAmount': '县(市、区)补助金额(万元)',
        'citySubsidyAmount': '市补助金额(万元)',
        'allSubsidyAmount': '建议总补助金额(万元)',
        'availableMatchMoney': '可用兑现金额(万元)',
        'applyReason': '申请理由',
        'billboardTime': '发榜时间',
        'publishListTime': '揭榜时间',
        'publishListPerson': '揭榜人',
        'publishListTeam': '揭榜团队',
        'contractParta': '合同甲方',
        'contractPartb': '合同乙方',
        'contractPartc': '合同丙方',
        'contractType': '合同类型',
        'contractStartTime': '合同开始时间',
        'contractEndTime': '合同结束时间',
        'contractAmount': '签约金额',
        'tappingListExpert': '攻榜专家',
        'add': '添加',
        'serialNumber': '序号',
        'expertCertificateType': '专家证件类型',
        'expertCertificateNumber': '专家证件号码',
        'whetherPutinStorage': '是否入库',
        'operation': '操作',
        'modify': '修改',
        'delete': '删除',
        'syncStorageAndClose': '同步入库并关闭',
        'supportingMaterials': '佐证材料',
        'paymentVoucher': '支付凭证',
        'clickPaymentVoucher': '点击上传支付凭证',
        'paymentVoucherTip': '请上传支付凭证，且不超过10M',
        'contractVoucher': '合同凭证',
        'contractEnroll': '技术合同登记证明',
        'clickContractVoucher': '点击上传合同凭证',
        'clickContractEnroll': '点击上传技术合同登记证明',
        'clickInvoice': '点击上传发票',
        'uploadContractVoucher': '上传合同凭证',
        'contractVoucherTip': '请上传合同凭证，且不超过10M',
        'invoiceTip': '请上传发票，且不超过10M',
        'contractEnrollTip': '请上传技术合同登记证明，且不超过10M',
        'commitmentLetter': '承诺书',
        'templateSignUpload': '按照模板签字盖章，上传',
        'commitmentLetterDownload': '诚信承诺书模板下载',
        'clickCommitmentLetter': '点击上传承诺书',
        'commitmentLetterTip': '请上传承诺书，且不超过10M',
        'projectSection': '项目小节',
        'specialAuditReport': '专项审计报告',
        'projectSectionDownload': '项目小节模板下载',
        'clickProjectSection': '点击上传项目小节',
        'clickSpecialAuditReport': '点击上传专项审计报告',
        'projectSectionTip': '请上传项目小节，且不超过10M',
        'specialAuditReportTip': '请上传专项审计报告，且不超过10M',
        'listAuctionProofMaterials': '榜单竞拍证明材料',
        'clickListAuctionProofMaterials': '点击上传竞拍榜单证明材料',
        'listAuctionProofMaterialsTip': '请上传竞拍榜单证明材料，且不超过10M',
        'whetherPatentTechnology': '是否专利技术',
        'wantTransfer': '我要转化',
        'myRelease': '我发布的',
        'applyImmediately': '立即申请',
        'maturity': '成熟度',
        'transferAmount': '转化金额',
        'achievementSummary': '成果概述',
        'researchTeamIntroduce': '成果科研团队简介',
        'resultApplicationIndustry': '成果应用行业',
        'coreProductName': '核心产品名称',
        'mainApplicationAreas': '主要应用领域',
        'featuresInIndustry': '在行业中的特色',
        'marketProspectAnalysis': '市场前景分析',
        'patentInformation': '专利信息',
        'patentName': '专利名称',
        'patentdesc': '专利简述',
        'advancedTechnology': '技术先进性',
        'applicationCase': '应用案例',
        'targetEnterprisesCustomers': '产品转化后用户目标企业或客户',
        'businessModel': '商业模式',
        'developmentPlan': '发展规划',
        'revenueForecastThreeYear': '未来三年营收预测',
        'financingPlan': '融资计划',
        'listName': '榜单名称',
        'ownershipAchievementUnit': '成果所属权单位',
        'whetherPropertyDispute': '是否有产权争议',
        'whetherPropertyRights': '是否有知识产权',
        'propertyBatchNumber': '知识产权名批号',
        'propertyRightsDesc': '产权简要描述',
        'achievementConversionAmount': '成果转化金额(万元)',
        'digitalizeMaturity': '数字成熟度',
        'resultsCoreProductPhotosVideos': '成果核心产品照片或视频',
        'resultsCoreProductDesc': '成果核心产品介绍',
        'applicationCasePhotosVideos': '应用案例照片或视频',
        'applicationCaseDesc': '应用案例介绍',
        'coverPhoto': '封面照片',
        'clickSelectImg': '点击选择图片',
        'compellation': '姓名',
        'dutuies': '职务',
        'major': '专业',
        'workExperience': '工作经历',
        'workPerformance': '工作业绩',
        'reviewedNoModify': '已审核，无法修改',
        'submit': '提交',
        'resultPhotsAndVideos': '成果照片、视频',
        'productCompetitivenessAnalysis': '成果产品竞争力分析照片或视频',
        'productCompetitivenessAnalysisDesc': '成果照片、视频',
        'talentExpertRegister': '人才/专家注册',
        'wantRegister': '我要注册',
        'birthday': '出生日期',
        'gender': '性别',
        'certificateType': '证件类型',
        'certificateNumber': '证件号码',
        'mobilePhone': '移动电话',
        'email': '电子邮箱',
        'technicalTitle': '技术职称',
        'titleLevel': '职称级别',
        'degree': '学位',
        'graduateSchool': '毕业院校',
        'fullUnitName': '单位全称',
        'schoolDepartment': '所在学院或部门',
        'inProvince': '所在省份',
        'inCity': '所在地级市',
        'inCounty': '所在县市区',
        'researchDirection': '研究方向',
        'academicAchievement': '学术成就',
        'headPortrait': '头像',
        'headPortraitDesc': '上传头像能让信息更丰富（一寸照）',
        'clearData': '清空数据',
        'firstLevelDiscipline': '一级学科',
        'secondLevelDiscipline': '二级学科',
        'thirdLevelDiscipline': '三级学科',
        'determine': '确定',
        'clickUpload': '点击上传',
        'uplimit50m': '上传文件，且不超过50M',
        'title': '标题',
        'state': '状态',
        'watchList': '查看榜单',
        'watchJieBang': '查看揭榜',
        'reviewingList': '评榜中',
        'butAcceptance': '可验收',
        'canBeTrophy': '可奖榜',
        'completed': '已完成',
        'shelves': '已下架',
        'returned': '已退回',
        'refused': '已拒绝',
        'field': '领域',
        'draft': '草稿',
        'auditReturn': '审核退回',
        'auditRefused': '审核拒绝',
        'waitingPayment': '待付款',
        'expertReview': '专家评审',
        'reviewCompleted': '评审完成',
        'reviewPass': '评估通过',
        'reviewReturn': '评估退回',
        'reviewRefused': '评估拒绝',
        'reviewExample': '优秀样例',
        'resultsName': '成果名称',
        'entrustingUnitName': '委托单位名称',
        'evaluationType': '评价类型',
        'applicationStatus': '申请状态',
        'notDocking': '未对接',
        'docking': '对接',
        'evaluationOfMe': '我的评价',
        'evaluation': '评价',
        'haveEvaluation': '已评价',
        'startEvaluation': '开始评价',
        'notApply': '未申请',
        'toAudit': '待审计',
        'auditCompleted': '审计完成',
        'comptrollerRefused': '审计拒绝',
        'comptrollerReturn': '审计退回',
        'confirmToRedeem': '待兑现确认',
        'detail': '详情',
        'returnReason': '退回原因',
        'refuseReason': '拒绝原因',
        'jiebangDetail': '揭榜详情',
        'haveRead': '是否已读',
        'newsheadlines': '消息标题',
        'compatibility': '匹配度',
        'watch': '查看',
        'toBeConfirm': '待确认',
        'forDocking': '待对接',
        'docked': '已对接',
        'belongMunicipal': '所属市级',
        'appointmentTitle': '预约标题',
        'belongList': '所属榜单',
        'bookingStatus': '预约状态',
        'policyBy': '保单通过',
        'unveilingCompanyNum': '意向揭榜数',
        'returnPolicy': '保单退回',
        'projectName': '项目名称',
        'beInsuredName': '被保险单位名称',
        'createTime': '创建时间',
        'loanBy': '贷款通过',
        'loanBack': '贷款退回',
        'loanRefuse': '贷款拒绝',
        'R&DName': '研发名称',
        'listLoanOrNot': '是否榜单贷款',
        'publishGovernmentListCall': '发布政府榜单',
        'publish': '发布',
        'sinceCrack': '自攻克',
        'ended': '已结束',
        'undercarriage': '下架',
        'removeList': '删除',
        'watchCheckList': '查看验榜',
        'switchToGovernmentList': '转政府榜单',
        'send': '发送',
        'whatCanIDoForYou': '您好，请问有什么可以为您效劳呢?',
        'jieBangHeaded': '揭榜挂帅',
        'machineService': '机器智能客服为您服务',
        'inviteYouJieBang': '邀您揭榜',
        'latestBillboard': '最新发榜',
        'inBatch': '换一批',
        'fitWithMe': '与我适配度',
        'participateInImmediately': '立即参与',
        'jieBangUser': '揭榜用户',
        'jieBangType': '揭榜类型',
        'personage': '个人',
        'team': '团队',
        'appointmentToEnterprise': '预约到企',
        'begin': '开始',
        'end': '结束',
        'signOrNot': '是否签约',
        'noSign': '未签约',
        'signingTime': '签约时间',
        'contractInformation': '合同信息',
        'supplementary': '补充',
        'startCheckList': '开始验榜',
        'checkListInfo': '验榜信息',
        'hardInformation': '困难信息',
        'titles': '职称',
        'professionalField': '专业领域',
        'workUnits': '工作单位',
        'cooperationMode': '合作方式',
        'teamNumber': '团队人数',
        'memberName': '成员姓名',
        'planOutlining': '方案简述',
        'planAttach': '方案附件',
        'attachment': '附件',
        'download': '下载',
        'agreeToSign': '同意签约',
        'redeemedAmount': '已兑榜金额',
        'wanYuan': '万元',
        'redeemAmount': '兑榜金额',
        'continueCash': '继续兑榜',
        'agreeToCash': '同意兑榜',
        'cashTime': '兑榜时间',
        'principalPartya': '委托方（甲方）',
        'localPlace': '住所地',
        'projectContactPerson': '项目联系人',
        'offphone': '电话',
        'tax': '传真',
        'principalPartyb': '受托方（乙方）',
        'partyCName': '丙方名称',
        'signingSite': '签约地点',
        'contractAmount2': '合同金额(万元)',
        'confirmContractSign': '确认签订合同',
        'enterpriseStartTime': '到企开始时间',
        'enterpriseEndTime': '到企结束时间',
        'unveilingApplyTime': '揭榜申请时间',
        'engagedIndustry': '从事行业',
        'products': '经营产品',
        'basicIntroduction': '基本介绍',
        'jieBangState': '揭榜状态',
        'cancelled2': '已解约',
        'paymentWay': '支付方式',
        'disposable': '一次性',
        'installment': '分期',
        'cashRecord': '兑现记录',
        'cashAmount': '兑现金额',
        'cashTime2': '兑现时间',
        'cashOperator': '兑榜操作人',
        'personNumber': '人数',
        'planAttachment': '方案附件',
        'dockingWay': '对接方式',
        'weChat': '微信',
        'mailbox': '邮箱',
        'appointmentTime': '预约时间',
        'appointmentEnterpriseTime': '预约到企时间',
        'subImmediately': '马上提交',
        'creditRating': '信用等级',
        'importantNote': '重要提示',
        'enterpriseCreditInformation': '企业信用信息',
        'temporarilyNo': '暂无',
        'transformationPlan': '转化方案',
        'attachmentUpload': '附件上传',
        'appointmentDockingTime': '预约对接时间',
        'dockingTime': '对接时间',
        'serviceCenter': '揭榜挂帅服务中心',
        'contactAddress': '联系地址',
        'dockingList': '对接榜单',
        'amount': '金额',
        'evaluationForMe': '对我的评价',
        'evaluationState': '评价状态',
        'watchEvaluation': '查看评价',
        'evaluationInfo': '评价信息',
        'dockingDetail': '对接详情',
        'leaveWord': '留言',
        'contactImmediately': '立即联系',
        'overallScore': '总体评分',
        'technicalAbility': '技术能力',
        'workAttitude': '工作态度',
        'serviceAttitude': '服务态度',
        'evaluationLabel': '评价标签',
        'evaluationContent': '评价内容',
        'suggestionAndComment': '建议和意见',
        'submitEvaluation': '提交评价',
        'workFitDegree': '工作配合度',
        'paymentTimeliness': '付款及时性',
        'foundationR&D': '企业研发基础',
        'enterpriseR&DCapability': '企业研发能力',
        'applicant': '申请人',
        'cannoContact': '无法联系',
        'added': '已添加',
        'listDocking': '榜单对接',
        'listJieBang': '榜单揭榜',
        'sign': '签约',
        'cash': '兑现',
        'complete': '完成',
        'watchPerformance': '查看绩效',
        'unitWanyuan': '单位：万元',
        'cashTimes': '兑现次数',
        'canFillInAmount': '可填金额',
        'achievementTransformationPerformance': '成果转化完成绩效',
        'marketOperatingIncome': '市场营业收入',
        'contractMaterials': '合同材料',
        'gongguanType': '攻关类型',
        'belongField': '管理领域',
        'researchType': '研发类型',
        'mainField': '主要领域',
        'firstInnovationChain': '所属标志性创新链',
        'secondInnovationChain': '二级标志性创新链',
        'thirdInnovationChain': '三级标志性创新链',
        'investment': '建议研发总投入（万元）',
        'timeLimit': '攻关时限',
        'conquerGoal': '攻关目标',
        'solvedScientific': '拟解决问题-科学问题',
        'solvedTechnical': '拟解决问题-技术问题',
        'achievementNature': '攻关成果性质',
        'unitProduct': '对标单位及产品（型号）',
        'relySituation': '可依托优势平台、人才情况',
        'demandUnit': '攻关成果潜在的应用单位及联系方式（3家以上）',
        'recommendUnit': '推荐攻关优势单位',
        'achievementLevel': '预期标志性成果及水平',
        'correspondenceAddress': '通讯地址',
        'downloadContractTemplate': '下载合同模板',
        'adopt': '已采纳',
        'unAdopt': '未采纳',
        'mySolicitation': '我的征集',
        'publicAgent': '代办人',
        'wait': '待立项',
        'normal': '发布',
        'blocked': '受阻',
        'termination': '终止',
        'newList':'新闻',
        'policyList':'政策',
        'outcomeEvaluationType':'成果评价类型',
        'outcomeEvaluationApply':'成果评价申请',
        'resultDisplay':'成果展厅',
        'resultDisplay-big':'成果展厅',
        'resultType':'成果类型',
        'transferScope':'转让范围',
        'exhibitName':'展品名称',
        'unitIntroduce':'单位简介',
        'projectExhibitIntroduce':'项目和展品介绍',
        'exhibitSpecificationSpecialDemand':'展品规格及特殊展示需求',
        'exhibitPhotoVideo':'展品照片、视频',
        'otherMattersExplain':'其他需要说明的事项',
        'resultInnovation':'成果创新性',
        'teamCircumstance':'人才团队情况',
        'progressDisplay':'进度展示',
        'progress':'进度',
        'downloadContract': '下载合同',
        'presentationContent': '内容展现',
        'pushExpert': '已推送专家',
        'disciplineClassification': '学科分类',
        'resultRegistry': '成果登记',
        'unlimited': '不限',
        'example': '样例',
        'provinceInnovation':'省创新馆',
        'abutmentStatus':'对接状态',
        'leadLevel':'先进水平',
        'addInnovationHall': '添加创新成果',
        'myInputShow': '我的创新展品',
        'talentBelong': '成果所属',

    },
    different: {
    },
};
