import Vue from "vue";
import vueCookie from "vue-cookie";
import VueLazyLoad from "vue-lazyload";
// 富文本组件
import Editor from "./components/Editor"

import moment from 'moment'
Vue.filter('dateFormat', (value, pattern = 'YYYY-MM-DD HH:mm:ss') => {  //需要什么格式，自行修改
  return moment(value).format(pattern)
})
Vue.filter('longToDate', function(value) {
  if (!value) return '';
  // 假设传入的值是一个长整型的时间戳（单位：毫秒）
  // 创建一个新的Date对象，并使用传入的值
  var date = new Date(value);
  // 使用toLocaleDateString或者其他格式化函数来转换日期
  return date.toLocaleDateString();
});

import {
  Message,
  MessageBox,
  Pagination,
  DatePicker,
  TimePicker,
  Button,
  Upload,
  Select,
  Option,
  OptionGroup,
  Dialog,
  Cascader,
  Radio,
  RadioButton,
  RadioGroup,
  Table,
  TableColumn,
  Switch,
  Popover,
  Form,
  FormItem,
  Input,
  InputNumber,
  Checkbox,
  CheckboxGroup,
  Rate,
  Tag,
  Carousel,
  CarouselItem,
  Progress,
  Row,
  Col,
  Image,
  Empty,
  Steps,
  Step,
  Tooltip,
  Dropdown
} from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "element-ui/lib/theme-chalk/table-column.css";
import "element-ui/lib/theme-chalk/table.css";
import Validate from "./filter/validate";
import VueFullPage from "vue-fullpage.js";
import VueAnimateNumber from "vue-animate-number";
import htmlToPdf from '@/utils/htmlToPdf';
Vue.use(htmlToPdf);

import App from "./App.vue";
import router from "./router";
import store from "./store";
import login from "./utils/login";
import Loading from "./utils/loading";
import "./components/js/common/plugin";

import "vant/lib/index.css";
import preview from 'vue-photo-preview'
import 'vue-photo-preview/dist/skin.css'
Vue.use(preview)

import VueAwesomeSwiper from 'vue-awesome-swiper'
// import style (>= Swiper 6.x)
import 'swiper/swiper-bundle.css'
import storage from './storage/localStorage';

import { Base64 } from 'js-base64'
Vue.prototype.$Base64 = Base64;
import i18n from './assets/language/index';

Vue.component('Editor', Editor)

import wx from 'weixin-js-sdk';
Vue.prototype.wx = wx;


Vue.use(storage);
Vue.use(VueAwesomeSwiper)

Vue.use(vueCookie);
Vue.use(VueLazyLoad, {
  loading: "/common/loading-svg/loading-bars.svg",
});
Vue.use(Pagination);
Vue.use(DatePicker);
Vue.use(TimePicker);
Vue.use(Upload);
Vue.use(Button);
Vue.use(Dialog);

Vue.use(Validate);
Vue.use(Select);
Vue.use(Option);
Vue.use(OptionGroup);
Vue.use(Cascader);
Vue.use(VueFullPage);
Vue.use(Radio);
Vue.use(RadioButton);
Vue.use(RadioGroup);
Vue.use(VueAnimateNumber);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Loading);
Vue.use(Switch);
Vue.use(Popover);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Input);
Vue.use(InputNumber);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Rate);
Vue.use(Tag);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Progress);
Vue.use(Row);
Vue.use(Col);
Vue.use(Image);
Vue.use(Empty);
Vue.use(Steps);
Vue.use(Step);
Vue.use(Tooltip);
Vue.use(Dropdown);

import Meta from 'vue-meta';
Vue.use(Meta);

import VueTippy from "vue-tippy";
Vue.use(VueTippy);

// 路由登录拦截
router.beforeEach((to, from, next) => {
  if (to.fullPath.indexOf('/pc/index') != -1 && from.fullPath.indexOf('/mb/index') != -1) {
    ZWJSBridge.onReady(function () {
      ZWJSBridge.close();
    });
  }
  // 要不要对meta.loginRequire属性做监控拦截
  if (
    to.matched.some(function (item) {
      return item.meta.loginRequire;
    })
  ) {
    let userToken = localStorage.getItem("userToken");
    if (Tool.isEmpty(userToken)) {
      login.install({
        fromPath: to.fullPath,
      });
    } else {
      next();
    }
  } else {
    next();
  }
  if (Tool.isMobile()) {
    if (to.path.indexOf("/pc") !== -1) {
      next({ path: to.fullPath.replace("/pc", "/mb") });
    }
  } else {
    if (to.path.indexOf("/mb") !== -1) {
      next({ path: to.fullPath.replace("/mb", "/pc") });
    }
  }
  //pc端
  if (to.name.indexOf("pc-index") != -1) {
    store.dispatch("saveHeaderFlag", 1);
    store.dispatch("saveFooterFlag", "home");
  }
  if (to.name.indexOf("pc-technology") != -1) {
    store.dispatch("saveHeaderFlag", 2);
    store.dispatch("saveFooterFlag", "demandlist");
  }
  if (to.name.indexOf("pc-talent") != -1) {
    store.dispatch("saveHeaderFlag", 3);
    store.dispatch("saveFooterFlag", "resultsList");
  }
  if (to.name.indexOf("pc-expert") != -1) {
    store.dispatch("saveHeaderFlag", 4);
  }
  if (to.name.indexOf("pc-alliance") != -1) {
    store.dispatch("saveHeaderFlag", 5);
  }
  if (to.name.indexOf("pc-news") != -1) {
    store.dispatch("saveHeaderFlag", 6);
    store.dispatch("saveFooterFlag", "newscenter");
  }
  if (to.name.indexOf("pc-center") != -1) {
    store.dispatch("saveHeaderFlag", 7);
  }
  if (to.name.indexOf("pc-govList") != -1) {
    store.dispatch("saveHeaderFlag", 8);
    store.dispatch("saveFooterFlag", "govList");
  }
  if (to.name.indexOf("pc-technology-award-apply") != -1) {
    store.dispatch("saveHeaderFlag", 9);
    store.dispatch("saveFooterFlag", "technologyAwardApply");
  }

  //手机端
  if (to.name.indexOf("mb-index") != -1) {
    store.dispatch("saveHeaderFlag", 1);
    store.dispatch("saveFooterFlag", "home");
  }
  if (to.name.indexOf("mb-technology") != -1) {
    store.dispatch("saveHeaderFlag", 2);
    store.dispatch("saveFooterFlag", "demandlist");
  }
  if (to.name.indexOf("mb-govList") != -1) {
    store.dispatch("saveHeaderFlag", 8);
    store.dispatch("saveFooterFlag", "govList");
  }
  if (to.name.indexOf("mb-talent") != -1) {
    store.dispatch("saveHeaderFlag", 3);
    store.dispatch("saveFooterFlag", "resultsList");
  }
  if (to.name.indexOf("mb-news") != -1) {
    store.dispatch("saveHeaderFlag", 6);
    store.dispatch("saveFooterFlag", "newscenter");
  }

  if (to.name.indexOf("mb-my-center") != -1) {
    store.dispatch("saveHeaderFlag", 7);
    store.dispatch("saveFooterFlag", "mycenter");
  }
  if (to.name.indexOf("mb-mini-jiebang-result") != -1) {
    store.dispatch("saveHeaderFlag", 8);
    store.dispatch("saveFooterFlag", "mycenter");
  }
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
});

Vue.prototype.$message = Message;
Vue.prototype.$messageBox = MessageBox;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$login = login;
Vue.config.productionTip = false;

window.APPLICATION = new Vue({
  store,
  router,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
